import { extendTheme } from '@chakra-ui/react';
import { withProse } from '@nikolovlazar/chakra-ui-prose';

const colors = {
  dark: {
    10: '#F9F9F9',
    50: '#F2F2F2',
    100: '#DBDBDB',
    200: '#C4C4C4',
    300: '#ADADAD',
    400: '#969696',
    500: '#808080',
    600: '#666666',
    700: '#4D4D4D',
    800: '#333333',
    900: '#1A1A1A',
  },
  app: {
    bg: '#FFFFFF',
    primary: '#FF9900',
    primaryDark: '#0a002b',
  },
  prose: {
    p: '#666666',
  },
};

const fonts = {
  heading: `'Inter', sans-serif`,
  body: `'Inter', sans-serif`,
};

const components = {
  Container: {
    baseStyle: {
      maxW: 'container.xl',
      paddingInlineStart: '0rem',
      paddingInlineEnd: '0rem',
    },
    defaultProps: {
      px: { base: 4, md: 0 },
    },
  },
  IconButton: {
    variants: {
      primary: {
        // bgGradient: 'linear(to-r, app.primary, orange.500)',
        bg: 'orange.400',
        color: 'white',
        transition: 'all 0.2s ease-in-out',

        _hover: {
          // bgGradient: 'linear(to-r, app.primary, app.primary)',
          bg: 'app.primary',
          color: 'white',
          transition: 'all 0.2s ease-in-out',
        },
      },
    },
  },
  Button: {
    baseStyle: {
      borderRadius: '9999px',
      fontWeight: '500',
      _hover: {
        textDecoration: 'none',
      },
    },
    defaultProps: {
      fontSize: 'xs',
    },
    variants: {
      primary: {
        // bgGradient: 'linear(to-r, app.primary, orange.500)',
        bg: 'orange.400',
        color: 'white',
        transition: 'all 0.2s ease-in-out',

        _hover: {
          // bgGradient: 'linear(to-r, app.primary, app.primary)',
          bg: 'app.primary',
          color: 'white',
          transition: 'all 0.2s ease-in-out',
        },
      },
      primaryAlt: {
        bg: 'blackAlpha.200',
        color: 'black',
        transition: 'all 0.2s ease-in-out',
        _hover: {
          bg: 'black',
          color: 'app.primary',
          transition: 'all 0.2s ease-in-out',
        },
      },
    },
    // variants: {
    //   solid: {
    //     bg: "brand.primary",
    //   },
    // },
  },
};
const styles = {
  global: {
    body: {
      bg: 'app.bg',
    },
  },
};

const overrides = {
  components,
  styles,
  fonts,
  colors,
  config: {
    cssVarPrefix: 'bitcoinmart',
  },
};

const theme = extendTheme(overrides, withProse());

export default theme;
