import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import React, { useState } from 'react'

export default function CustomMarker(props) {
    const mapInstance = props.mapInstance;
    const [markerInstance, setMarkerInstance] = useState();
    const { isOpen, onToggle } = useDisclosure();
    const location = props.location;

    return (
        <div>
            {mapInstance && <Marker onLoad={marker => setMarkerInstance(marker)} position={location} onClick={onToggle}/>}
            {isOpen && 
                <InfoWindow anchor={markerInstance} position={location} onCloseClick={onToggle}>
                    <Box>
                        <Text fontSize="medium" fontWeight="bold" mb={1}>{props.title ? props.title : "Square One"}</Text>
                        <Text>{props.address ? props.address : "9 blue road, Toronto, ON, CA"}</Text>
                    </Box>
                </InfoWindow>
            }
        </div>
    );
}