import React from 'react'
import PageLayout from '../layouts/page-layout'
import ReactMarkdown from 'react-markdown'
import { Prose } from '@nikolovlazar/chakra-ui-prose'
import { useFetchMd } from '../../common/util/fetch-md'
import contactUsMd from '../../assets/md/contact-us.md'
import { Helmet } from 'react-helmet-async'
import { ContactForm } from '../../assets/mailchimp/mailchimp-forms'

const ContactUsPage = () => {

    const { markdown } = useFetchMd(contactUsMd)

    return (
        <PageLayout title="Contact Us" padChildren>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Get in touch with and get all your questions answered promptly." />
                <link rel='canonical' href='/contact-us' />
            </Helmet>
            <ContactForm />
            <Prose>
                <ReactMarkdown linkTarget="_blank">{markdown}</ReactMarkdown>
            </Prose>
        </PageLayout>
    )
}

export default ContactUsPage