import React from 'react'
import { useFetchMd } from '../../common/util/fetch-md'
import compliancePolicyMd from '../../assets/md/compliance-policy.md'
import PageLayout from '../layouts/page-layout'
import { Helmet } from 'react-helmet-async'
import { Prose } from '@nikolovlazar/chakra-ui-prose'
import ReactMarkdown from 'react-markdown'

const CompliancePolicyPage = () => {
    const { markdown } = useFetchMd(compliancePolicyMd)
    return (
        <PageLayout>
            <Helmet>
                <title>Compliance Policy</title>
                <meta name="description" content="Get in touch with and get all your questions answered promptly." />
                <link rel='canonical' href='/compliance-policy' />
            </Helmet>
            <Prose>
                <ReactMarkdown linkTarget="_blank">{markdown}</ReactMarkdown>
            </Prose>
        </PageLayout>
    )
}

export default CompliancePolicyPage