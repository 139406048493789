import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useDisclosure,
    Image,
    Spacer,
} from '@chakra-ui/react';

import logo from '../../assets/branding/bitcoin-mart-logo.png'
import NAV_ITEMS from '../../__WEBSITE_DATA/navbar-items';

import {
    RiMenuFill,
    RiArrowDownSFill,
    RiCloseFill,
    RiArrowRightLine,
} from 'react-icons/ri'

const RegisterButton = () => (
    <Button as={Link} size="sm" p={5} href="/register" variant="primary">Register</Button>
)

export default function Navbar() {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Box zIndex="10000">
            <Flex
                bg="white"
                color="dark.800"
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                alignItems={'center'}

            >
                <Link href="/">
                    <Image src={logo} h={16} w="auto" />
                </Link>

                <Spacer />

                <Flex justify={'start'} alignItems={'center'}>
                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <DesktopNav />
                    </Flex>
                    <Box
                        display={{ base: 'block', md: 'none' }}>
                        <IconButton
                            onClick={onToggle}
                            size="lg"
                            icon={
                                isOpen ? <RiCloseFill /> : <RiMenuFill />
                            }
                            variant="ghost"
                            aria-label="Toggle Navigation"
                        />
                    </Box>

                </Flex>
            </Flex>

            <Collapse in={isOpen} animateOpacity boxShadow="md">
                <MobileNav />
            </Collapse>



        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Stack direction="row" spacing={4} alignItems="center" pos="sticky" top="0">
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger="click" placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Button
                                as={Link}
                                p={2}
                                href={navItem.href ?? '#'}
                                fontSize="sm"
                                size="sm"
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                    textDecoration: 'none',
                                    color: linkHoverColor,
                                }}
                                rightIcon={navItem.children && <RiArrowDownSFill />}
                                variant="ghost">
                                {navItem.label}
                            </Button>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                boxShadow="xl"
                                bg={popoverContentBgColor}
                                p={2}

                            >
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
            <RegisterButton />
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <Link
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            px={4}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('orange.50', 'gray.900') }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'app.primary' }}
                        fontWeight={600}>
                        {label}
                    </Text>
                    <Text fontSize="xs" color="dark.500">{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color="app.primary" as={RiArrowRightLine} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={RiArrowDownSFill}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0 !important' }}>
                <Stack
                    mt={0}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

