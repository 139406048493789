import { Container } from "@chakra-ui/react";
import React from 'react'
import { Helmet } from "react-helmet-async";
import PageLayout from "../layouts/page-layout";

import TOSMd from '../../assets/md/tos.md'
import { useFetchMd } from "../../common/util/fetch-md";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import ReactMarkdown from 'react-markdown'
// var TOSTextHTML = require('./TOSText.HTML');

export default function TOS() {

    const { markdown } = useFetchMd(TOSMd)

    return (
        <Container >
            <Helmet>
                <title>Terms of Service</title>
                <meta name="description" content="Learn about our terms of service." />
                <link rel='canonical' href='/terms-of-service' />
            </Helmet>
            <PageLayout title="Terms Of Service">
                <Prose>
                    <ReactMarkdown linkTarget="_blank">{markdown}</ReactMarkdown>
                </Prose>
                {/* <iframe title="Terms Of Service" src={TOSTextHTML} style={{ minWidth: '100%', minHeight: '4800px' }}></iframe> */}
            </PageLayout>
        </Container>
    );
}