import { Prose } from '@nikolovlazar/chakra-ui-prose'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { BusinessContactForm } from '../../assets/mailchimp/mailchimp-forms'
import PageLayout from '../layouts/page-layout'
import ReactMarkdown from 'react-markdown'
// import { Box } from '@chakra-ui/react'

const intro = `
Host a cryptocurrency ATM in your store today to start earning an additional guaranteed profit.  By hosting a cryptocurrency ATM you can expect to see an increase of 100+ new customers in your store a month that have the potential of purchasing goods from you.  Another benefit of hosting a cryptocurrency ATM is a better utilization of floor space or a reduced inventory as the ATM provides secured profit every month.  
                                
At Bitcoin Mart we look to offer considerable commission for machine placements.  Reach out if you have any questions and we can start to discuss how we can help you become more profitable.
`
const StartEarningToday = () => {
    return (
        <>
            <Helmet>
                <title>Start Earning Today</title>
                <meta name="description" content="" />
                <link rel='canonical' href='/start-earning-today' />
            </Helmet>
            <PageLayout title="Start Earning Today!" padChildren>
                <Prose>
                    <ReactMarkdown children={intro} />
                </Prose>
                {/* <Box display="flex" justifyContent={'center'}> */}
                <BusinessContactForm />
                {/* </Box> */}
            </PageLayout>
        </>
    )
}

export default StartEarningToday