import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFetchMd } from '../../common/util/fetch-md'

import whatIsBitcoin from '../../assets/md/learn/what-is-bitcoin.md'
import whatIsEthereum from '../../assets/md/learn/what-is-ethereum.md'
import whatIsLitecoin from '../../assets/md/learn/what-is-litecoin.md'

import PageLayout from '../layouts/page-layout'
import ReactMarkdown from 'react-markdown'
import { Prose } from '@nikolovlazar/chakra-ui-prose'
import { AspectRatio, Image } from '@chakra-ui/react'
import BtcCover from '../../assets/imgs/bitcoin-cover.jpg'
import EthCover from '../../assets/imgs/ethereum-cover.jpg'
import LtcCover from '../../assets/imgs/litecoin-cover.jpg'
import { Helmet } from 'react-helmet-async'

const LearnSubpage = () => {
    const { id } = useParams()
    const [md, setMd] = React.useState(undefined)
    const [content, setContent] = React.useState({})

    useEffect(() => {
        switch (id) {
            case 'bitcoin':
                setMd(whatIsBitcoin)
                setContent({ title: 'Learn About Bitcoin', img: BtcCover })
                break;
            case 'ethereum':
                setMd(whatIsEthereum)
                setContent({ title: 'Learn About Ethereum', img: EthCover })
                break;
            case 'litecoin':
                setMd(whatIsLitecoin)
                setContent({ title: 'Learn About Litecoin', img: LtcCover })
                break;
            default:
                break;
        }


    }, [id])


    const { markdown } = useFetchMd(md)

    return (
        <div>
            <Helmet>
                <title>{content.title}</title>
                <meta name="description" content={md} />
                <link rel='canonical' href='/learn' />
            </Helmet>

            <PageLayout padChildren title={content.title}>
                <AspectRatio ratio={21 / 9}>
                    <Image src={content.img}></Image>
                </AspectRatio>
                <Prose>
                    <ReactMarkdown>{markdown}</ReactMarkdown>
                </Prose>
            </PageLayout>
        </div>
    )
}

export default LearnSubpage