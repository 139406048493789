module.exports = [
  {
    label: 'Buy',
    children: [
      {
        label: 'With Cash',
        href: '/buy-with-cash',
      },
      {
        label: 'With Interac E-Transfer',
        href: '/buy-with-etransfer',
      },
    ],
  },
  {
    label: 'Sell',
    href: 'sell',
  },
  {
    label: 'Support',
    children: [
      {
        label: 'FAQ',
        subLabel: 'Find frequently asked questions about our services!',
        href: '/faq',
      },
      {
        label: 'Learn',
        subLabel: 'New to Bitcoin? Learn about cryptocurrencies and more!',
        href: '/learn',
      },
      {
        label: 'Contact Us',
        subLabel: 'Questions or concerns?',
        href: '/contact-us',
      },
    ],
  },
  {
    label: 'Start Earning Today',
    href: '/start-earning-today',
  },
];
