module.exports = {
  BitcoinMart: [
    {
      label: 'Buy with Cash',
      href: '/buy-with-cash',
    },
    {
      label: 'Buy with Interac',
      href: '/buy-with-etransfer',
    },
    {
      label: 'Sell',
      href: '/sell',
    },
    {
      label: 'Start Earning Today',
      href: '/start-earning-today',
    },
    {
      label: 'Register',
      href: '/register',
    },
  ],

  Support: [
    {
      label: 'FAQ',
      href: '/faq',
    },
    {
      label: 'Learn',
      href: '/learn',
    },
    {
      label: 'Contact Us',
      href: '/contact-us',
    },
    {
      label: 'Compliance Policy',
      href: '/compliance-policy',
    },
    {
      label: 'Terms of Service',
      href: '/terms-of-service',
    },
    {
      label: 'Privacy Policy',
      href: '/privacy-policy',
    },
  ],
};
