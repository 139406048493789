import logo from '../../assets/branding/bitcoin-mart-logo.png'

import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Image,
  HStack,
  Icon
} from '@chakra-ui/react';

import { FaInstagram, FaTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa';

import SOCIAL_LINKS from '../../__WEBSITE_DATA/social-links'
import FOOTER_ITEMS from '../../__WEBSITE_DATA/footer-items'

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight="600" fontSize={'md'}>
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <>
      <Box
        fontSize="sm"
        bg="orange.50"
        color="black">
        <Container as={Stack} py={10}>
          <SimpleGrid
            templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
            spacing={8}
            justifyItems={'center'}>
            <Box justifySelf="start">
              <Image src={logo} h={32} w="auto" />
            </Box>
            {Object.entries(FOOTER_ITEMS).map(([k, v]) => {
              return <Stack align={'flex-start'}>
                <ListHeader>{k}</ListHeader>
                {v.map((link) => {
                  return <Link _hover={{ opacity: 0.9 }} className="smooth" href={link.href}>{link.label}</Link>
                })}
              </Stack>
            })}
            <Stack align={'flex-start'}>
              <ListHeader>Follow Us</ListHeader>
              {Object.entries(SOCIAL_LINKS).map(([k, v]) => {
                let icon;
                switch (k) {
                  case 'Facebook':
                    icon = FaFacebook
                    break;
                  case 'Twitter':
                    icon = FaTwitter
                    break;
                  case 'Instagram':
                    icon = FaInstagram
                    break;
                  case 'LinkedIn':
                    icon = FaLinkedin
                    break;
                  default:
                    break;
                }
                return <Link href={v}>
                  <HStack>
                    <Icon as={icon} />
                    <Text>{k}</Text>
                  </HStack>
                </Link>
              })}
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
      <Box bg="app.primary" px={4} py={2}><Container><Text fontSize="sm" color="white">
        © {new Date().getFullYear()} BitcoinMart. All rights reserved
      </Text></Container></Box>
    </>
  );
}