import {
    chakra,
    Stack,
    Button,
} from '@chakra-ui/react';

const FindALocationSection = () => {
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            px={{ base: 4, md: 0 }}
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: 2, md: 8 }}
        >
            <chakra.h1
                textAlign={'center'}
                fontSize={{ base: '2xl', md: '3xl' }}
                fontWeight='500'
                color="white">
                Get Started Now!
            </chakra.h1>
            <Button as="a" href="/buy-with-cash" size="lg" variant="primary">See All ATM Locations -{'>'}</Button>

        </Stack>
    );
}


export default FindALocationSection