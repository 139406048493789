
import React, { useReducer, useEffect, useState } from 'react'
import { FormControl } from '@chakra-ui/react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { AsyncSelect } from 'chakra-react-select';

export default function Search({ setLookupAddress, reset }) {

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        if (reset === true) {
            forceUpdate();
        }
    }, [reset]);

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (newValue) => {
        if (newValue == null) {
            reset();
            return;
        }

        const address = newValue.value;
        setValue(address, false);
        clearSuggestions();

        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setLookupAddress({ lat, lng });
    };

    return (
        <FormControl onSelect={handleSelect}>
            <AsyncSelect
                onChange={handleSelect}
                isDisabled={!ready}
                placeholder='Address'
                isClearable={true}
                loadOptions={(inputValue, callback) => {
                    setValue(inputValue);
                    const dataDescription = []
                    data.forEach(({ place_id, description }) => {
                        dataDescription.push({ value: description, label: description });
                    })
                    callback(dataDescription);
                }}
            />
        </FormControl>
    )
}