import React, { useEffect, useState } from 'react'
import { Box, Divider, IconButton, Text, Container, Stack, Center, Button } from '@chakra-ui/react';
import { useLoadScript } from "@react-google-maps/api";
import { BiCurrentLocation } from 'react-icons/bi';
import { VscDebugRestart } from 'react-icons/vsc'
import Map from './Map';

import Search from './Search';
import { Helmet } from 'react-helmet-async';
import PageLayout from '../layouts/page-layout';
import { ATMLocations as LocationJSON } from '../../__WEBSITE_DATA/atm-locations';


function toRad(deg) {
    return deg * (Math.PI / 180);
}

function calcDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var dlat1 = toRad(lat1);
    var dlat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(dlat1) * Math.cos(dlat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}

export default function Location() {
    const distance = 10;
    const [center, setCenter] = useState({ lat: 44, lng: -80 });
    const LocationDataOrig = LocationJSON.Locations;
    const [LocationData, setLocationData] = useState(LocationDataOrig);
    const [LookupAddress, setLookupAddress] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const libraries = ["places"];
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries
    });

    const findNearLocations = (distance) => {
        const searchRes = [];
        var address = null;
        if (LookupAddress !== null) {
            address = LookupAddress;
        } else if (userLocation !== null) {
            address = userLocation;
        }

        LocationDataOrig.map(item => {
            if (calcDistance(address.lat, address.lng, item.latitude, item.longitude) <= distance) {
                searchRes.push(item);
            }
        });
        setLocationData(searchRes);
    }

    const getUserLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                setUserLocation({ lat: latitude, lng: longitude });
            },
            (error) => alert(error.message),
            { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
        );
    }

    const handleClose = () => {
        if (LookupAddress) {
            setLookupAddress(null);
            setLocationData(LocationDataOrig);
        } else if (userLocation) {
            setUserLocation(null);
            setLocationData(LocationDataOrig);
        }
    }

    useEffect(() => {
        if (LookupAddress == null)
            return;
        findNearLocations(distance);
    }, [LookupAddress])

    useEffect(() => {
        if (userLocation == null)
            return;
        findNearLocations(distance);
    }, [userLocation])


    return (
        <Container>
            <Helmet>
                <title>Buy with Cash</title>
                <meta name="description" content="Find an ATM near you with an interactive map." />
                <link rel='canonical' href='/buy-with-cash' />
            </Helmet>

            <PageLayout title="Find an ATM Near You">
                <Stack spacing={4}>
                    <Stack direction={{ base: 'column', md: 'row' }} height={{ base: '100vh', md: '50vh' }} spacing={0}>
                        <Stack w={{ base: '100%', md: '30vw' }} direction="column" bg="dark.10" p={4}>
                            <Stack direction="row">
                                {isLoaded ?
                                    <>
                                        {userLocation === null ?
                                            <Search reset={handleClose} setLookupAddress={setLookupAddress} /> :
                                            null
                                        }
                                    </>
                                    :
                                    null
                                }
                                {LookupAddress === null && userLocation === null ?
                                    <IconButton ml={2} variant="primary" rounded="5px" icon={<BiCurrentLocation />} onClick={getUserLocation}></IconButton> :
                                    <>
                                        {userLocation === null ?
                                            null :
                                            <Button variant="primary" rounded="5px" leftIcon={<VscDebugRestart />} onClick={handleClose} width="100%">Reset</Button>
                                        }
                                    </>
                                }

                            </Stack>
                            <Stack overflow="auto" maxH={{ base: '30vh', md: 'inherit' }}>
                                {LookupAddress ? <Text fontSize="medium" mb={2}>The following locations are in {distance} km from the given Address.</Text> : null}
                                {userLocation ? <Text fontSize="medium" mb={2}>The following locations are in {distance} km from your location.</Text> : null}
                                {LocationData.length === 0 ? <Text fontSize="medium" fontWeight="light">No Locations Found</Text> : null}
                                {
                                    LocationData.map(item =>
                                        <Box p={1} _hover={{ cursor: "pointer", bg: "dark.50" }} onClick={() => setCenter({ lat: item.latitude, lng: item.longitude })}>
                                            <Text fontSize="md" fontWeight="bold">{item.Title}</Text>
                                            <Text fontSize="sm">{item.Address}</Text>
                                            <Divider size="3px" borderColor="black" mt={1}></Divider>
                                        </Box>
                                    )
                                }
                            </Stack>
                        </Stack>
                        <Map center={center} isLoaded={isLoaded} />
                    </Stack>
                    <Center p={{ base: 4, md: 0 }}><Button as="a" href="/faq" size="lg" w="100%" borderRadius="none">Why buy from an ATM?</Button></Center>

                </Stack>
            </PageLayout>
            {/* <Flex direction="row" justify="center" mb={10}>
                <Box w='80vw' h='50vh' bg="black" rounded="10px">
                    <Flex h="inherit" direction="row">
                        <Box w="20vw" h="" bg="dark.10" roundedLeft="8px">
                            <Flex px={2} pt={2} >
                                {isLoaded ? <Search reset={resetSearch} setLookupAddress={setLookupAddress} /> : null}
                                {LookupAddress === null && userLocation === null ?
                                    <IconButton ml={2} rounded="5px" icon={<BiCurrentLocation />} onClick={getUserLocation}></IconButton> :
                                    <IconButton ml={2} rounded="5px" icon={<AiOutlineClose />} onClick={handleClose}></IconButton>
                                }
                            </Flex>
                            <Flex mx={2} mt={2} direction="column" bg="dark.10">
                                {LookupAddress ? <Text fontSize="medium" mb={2}>The following locations are in {distance} km from the given Address.</Text> : null}
                                {userLocation ? <Text fontSize="medium" mb={2}>The following locations are in {distance} km from your location.</Text> : null}
                                {LocationData.length === 0 ? <Text fontSize="medium" fontWeight="light">No Locations Found</Text> : null}
                                {
                                    LocationData.map(item =>
                                        <Box p={1} _hover={{ cursor: "pointer", bg: "gray.100" }} onClick={() => setCenter({ lat: item.latitude, lng: item.longitude })}>
                                            <Text fontSize="large">{item.Title}</Text>
                                            <Text fontSize="small">{item.Address}</Text>
                                            <Divider size="3px" borderColor="black" mt={1}></Divider>
                                        </Box>
                                    )
                                }
                            </Flex>
                        </Box>
                        <Map center={center} isLoaded={isLoaded} />
                    </Flex>
                </Box>
            </Flex> */}
        </Container>
    )
}