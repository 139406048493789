import { Container } from "@chakra-ui/react";
import React from 'react'
import { Helmet } from "react-helmet-async";
import PageLayout from "../layouts/page-layout";

import { Prose } from "@nikolovlazar/chakra-ui-prose";
import ReactMarkdown from 'react-markdown'
import { useFetchMd } from "../../common/util/fetch-md";
import PrivacyPolicyMd from '../../assets/md/privacy-policy.md'
// var PrivacyPolicyHTML = require('./PrivacyPolicy.HTML');
export default function PrivacyPolicy() {

    const { markdown } = useFetchMd(PrivacyPolicyMd)
    return (
        <Container >
            <Helmet>
                <title>Privacy Policy</title>
                <meta name="description" content="Learn about our privacy policy." />
                <link rel='canonical' href='/privacy-policy' />
            </Helmet>
            <PageLayout title="Privacy Policy">
                <Prose>
                    <ReactMarkdown linkTarget="_blank">{markdown}</ReactMarkdown>
                </Prose>
                {/* <iframe title="Privacy Policy" src={PrivacyPolicyHTML} style={{ minWidth: '100%', minHeight: '3500px' }}></iframe> */}
            </PageLayout>
        </Container>
    );
}