import {
    Container,
    Heading,
    Stack,
    Text,
    Button,
    Image,
    Box,
} from '@chakra-ui/react';
import React from 'react';
import { BTCLogo, LTCLogo, ETHLogo } from '../../../assets/imgs';

const CoinFactory = (coin) => {
    let img = ''
    let borderColor = ''
    let bg = 'blackAlpha.100'
    switch (coin) {
        case 'btc':
            img = BTCLogo
            borderColor = 'orange.300'
            bg = "orange.100"
            break;
        case 'ltc':
            img = LTCLogo
            borderColor = "blue.500"
            bg = 'blue.100'
            break;
        case 'eth':
            img = ETHLogo
            bg = "dark.100"
            borderColor = "dark.500"
            break;
        default:
            break;
    }

    return <Box p={8} style={{ marginLeft: '-1px' }}>
        <Image src={img} boxSize={{ base: '64px', md: '128px' }}></Image>
    </Box>
}

const LandingSection = () => {
    return (
        <Container maxW={'5xl'}>
            <Stack
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 20, md: 28 }}>
                <Stack align="center" direction="row" spacing={0}>
                    {['eth', 'btc', 'ltc'].map(coin => {
                        return CoinFactory(coin)
                    })}
                </Stack>

                <Heading
                    fontWeight={600}
                    fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                    lineHeight={'110%'}>
                    Start Stacking with {' '}
                    <Text as={'span'} color={'orange.400'}>
                        Cash
                    </Text>
                </Heading>
                <Text fontSize="2xl" color={'gray.500'} maxW={'3xl'}>
                    The <i>quickest</i> and most <i>secure</i> <br /> way to get into <b>crypto</b>
                </Text>
                <Stack spacing={6} direction={'row'}>
                    <Button size="lg" variant="primary" p={9}>
                        <a href="/buy-with-cash">
                            BUY CRYPTO TODAY -{'>'}
                        </a>
                    </Button>
                </Stack>
            </Stack>
        </Container>
    );
}

export default LandingSection