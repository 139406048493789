import {
    Box,
    SimpleGrid,
    Stack,
    Heading,
    Text,
    Flex,
    Icon
} from '@chakra-ui/react';
import { RiLockPasswordFill, RiFlashlightFill, RiSpyFill, RiWalletFill, RiCustomerServiceFill } from 'react-icons/ri';

const CARDS_DATA = [
    {
        title: 'Safe & Secure',
        desc: 'Have full control of your own money today',
        icon: RiLockPasswordFill
    },
    {
        title: 'Lightning Fast',
        desc: 'Purchase crypto from our ATM in under 5 minutes',
        icon: RiFlashlightFill
    },
    {
        title: 'Stay Anonymous',
        desc: 'Buy Bitcoin, Ethereum or Litecoin without sharing personal information',
        icon: RiSpyFill,
    },
    {
        title: 'Easy Funding',
        desc: 'Purchase crypto with as little as $20',
        icon: RiWalletFill,
    },
    {
        title: 'We’re Here For You',
        desc: 'Customer support and help is always available',
        icon: RiCustomerServiceFill,
    }
]

function StatsCard(props) {
    const { title, desc, icon } = props;


    return (
        <Box
            textAlign="start"
            p={4}
            rounded={'sm'}>
            <Stack spacing={6} direction={{ base: 'row', md: 'column' }}>

                <Flex rounded="lg" bg="orange.100" border="solid 1px" boxSize={{ base: 12, md: '100%' }} borderColor="orange.200" p={{ base: 2, md: 8 }}><Icon color="app.primaryDark" boxSize="100%" as={icon} /></Flex>

                <Stack>
                    <Heading color="orange.300" fontSize="lg">{title}</Heading>
                    <Text fontSize="sm" color="prose.p">{desc}</Text>
                </Stack>

            </Stack>

        </Box>
    );
}

const CryptoWithCashSection = () => {
    return (
        <Box
            px={{ base: 4, md: 0 }}
        >
            <SimpleGrid columns={{ base: 1, md: 5 }} spacing={2}>
                {CARDS_DATA.map(data => { return <StatsCard title={data.title} desc={data.desc} icon={data.icon} /> })}
            </SimpleGrid>
        </Box>
    );
}


export default CryptoWithCashSection