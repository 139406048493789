import React from 'react'
import { Helmet } from 'react-helmet-async'
import { SubscribeForm } from '../../assets/mailchimp/mailchimp-forms'
import PageLayout from '../layouts/page-layout'

const ComingSoon = ({ title }) => {
    return (
        <>
            <Helmet>
                <title>{title} - Coming Soon!</title>
            </Helmet>
            <PageLayout maxW="container.sm" padChildren title="Coming Soon" subtitle="Sign up to get notified when this page is available.">
                <SubscribeForm />
            </PageLayout>
        </>
    )
}

export default ComingSoon