import {
    Box,
    chakra,
    SimpleGrid,
    Stack,
    Heading,
    Text,
    Icon,
    Divider,
    Flex,
    Image
} from '@chakra-ui/react';
import { RiSettings2Fill } from 'react-icons/ri';
import { ImQrcode } from 'react-icons/im';
import { FaMoneyBill, FaBitcoin } from 'react-icons/fa'
import PhoneWithMsg from '../../../assets/icons/phone-with-msg.svg'

const CARDS_DATA = [
    {
        title: 'Verify',
        desc: 'Choose crypto and verify phone number to start',
        icon: PhoneWithMsg
    },
    {
        title: 'Scan',
        desc: 'Scan your wallet QR code',
        icon: ImQrcode
    },
    {
        title: 'Insert',
        desc: 'Insert cash for the amount of crypto you would like to purchase',
        icon: FaMoneyBill,
    },
    {
        title: 'Receive',
        desc: 'We send you your crypto!',
        icon: FaBitcoin,
    },
]

function StatsCard(props) {
    const { title, desc, icon } = props;

    return (
        <Box
            textAlign="start"
            p={4}
            rounded={'sm'}
            zIndex={1}>
            <Stack spacing={6} direction={{ base: 'row', md: 'column' }}>
                <Flex rounded="lg" border="solid 1px" boxSize={{ base: 12, md: '100%' }} borderColor="orange.200" p={{ base: 2, md: 8 }} bg="white" _hover={{ borderColor: "app.primary", bg: "orange.50" }}>
                    {title === 'Verify' ? (<Image src={PhoneWithMsg} />) :
                        (<Icon color="black" boxSize="100%" as={icon}>

                        </Icon>)}

                </Flex>
                <Stack>
                    <Heading color="app.primaryDark" fontSize="lg">{title}</Heading>
                    <Text fontSize="sm" color="prose.p">{desc}</Text>
                </Stack>
            </Stack>

        </Box >
    );
}

const HowToBuyCryptoSection = () => {
    return (
        <Stack
            py={{ base: 0, md: 16 }}
            spacing={10}
            px={{ base: 4, md: 0 }}
        >
            <Stack direction="row" alignItems="center">
                <Icon boxSize={8} as={RiSettings2Fill} />
                <chakra.h1
                    textAlign="left"
                    fontSize={'4xl'}
                    fontWeight={'bold'}
                    color="app.primaryDark">
                    How it Works
                </chakra.h1>
            </Stack>

            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 4, md: 8 }}>
                <Divider pos="absolute" alignSelf="center" left="0" borderColor="app.primary" display={{ base: "none", md: "block" }} />
                {CARDS_DATA.map((data, i) => { return <StatsCard step={i + 1} title={data.title} desc={data.desc} icon={data.icon} /> })}
            </SimpleGrid>
        </Stack>
    );
}


export default HowToBuyCryptoSection