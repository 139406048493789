/*
! MODIFY THIS TO MAKE CHANGES TO WEBSITE
*/

export const ATMLocations = {
  Locations: [
    {
      Title: 'Pearl Convenience',
      Address: '103-423 Wanless Drive, Brampton, ON, L7A 0N9',
      latitude: 43.716805011958904,
      longitude: -79.82332750740106,
    },
    {
      Title: 'Brampton Place',
      Address: '20333 place, Brampton, ON',
      latitude: 44.2,
      longitude: -79.9,
    },
    {
      Title: 'Guelph Place',
      Address: '711 place, Guelph, ON',
      latitude: 44.5,
      longitude: -80.2,
    },
    {
      Title: 'Mississauga Place',
      Address: '2 abcas place, Mississauga, ON',
      latitude: 41.5,
      longitude: -79.0,
    },
    {
      Title: 'Soup Restaurant',
      Address: '2 ladle place, Bowl, ON',
      latitude: 40.5,
      longitude: -78.0,
    },
  ],
};
