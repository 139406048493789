import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import theme from './config/chakra/chakraTheme';
import './styles/index.scss';
import Landing from './components/Landing/Landing';
import Location from './components/Location/Location';
import Navbar from './components/Navbar/navbar';
import Footer from './components/Footer/Footer';
import FAQPage from './components/FAQ';
import ContactUsPage from './components/ContactUs';
import LearnPage from './components/Learn';
import TOS from './components/TOS/TOS';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import StartEarningToday from './components/StartEarningToday/StartEarningToday';
import LearnSubpage from './components/Learn/learn-subpage';
import ComingSoon from './components/common/ComingSoon';
import { Helmet } from 'react-helmet-async';
import CompliancePolicyPage from './components/CompliancePolicy/CompliancePolicy';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Helmet defaultTitle="BitcoinMart" titleTemplate="%s | BitcoinMart" />
        <Flex flexDir="column" minH="100vh">
          <Navbar />
          <Flex flexGrow={1} flexDir="inherit">
            <Routes>
              <Route path="" element={<Landing />} />
              <Route path="*" element={<Navigate replace to="/" />} />
              <Route path="/buy-with-cash" element={<Location />} />
              <Route path="/sell" element={<ComingSoon title="Sell" />} />
              <Route
                path="/buy-with-etransfer"
                element={<ComingSoon title="Buy with E-Transfer" />}
              />
              <Route
                path="/start-earning-today"
                element={<StartEarningToday />}
              />
              <Route
                path="/register"
                element={<ComingSoon title="Register" />}
              />
              <Route path="learn">
                <Route index element={<LearnPage />} />
                <Route path=":id" element={<LearnSubpage />} />
              </Route>
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route path="/terms-of-service" element={<TOS />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/compliance-policy"
                element={<CompliancePolicyPage />}
              />
            </Routes>
          </Flex>
        </Flex>

        <Footer />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
