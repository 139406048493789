module.exports = {
  'What is Bitcoin?': `Check out our [Learn page](/learn) to find out more about Bitcoin, Ethereum and Litecoin`,

  'Why should I buy cryptocurrency at an ATM instead of buying online?': `Purchasing cryptocurrency at a Bitcoin Mart ATM is the most secure and anonymous way to acquire cryptocurrency today.  
    
  # Purchase Anonymously
  - All that is required to purchase any cryptocurrency at an ATM is a registered Canadian phone.  No names, addresses or other personal information needs to be provided.

  #	Taking Instant Control
  - On all online exchanges, when you send them money, they are holding your money or cryptocurrency on their platform.  If their company or platform gets hacked or goes bankrupt, there is a chance your funds are lost forever.  With an ATM the cryptocurrency you purchase is sent directly to you and we do not hold your money.  You hold your own money the way you should.
  # Purchase Instantly
  - Online exchanges can have a lengthy verification process that sometimes takes upwards of 2 days to complete.  In this time, the price can move away from your target price.  With ATMs it takes under 5 minutes to get verified and purchase cryptocurrency for the first time.
  # No Bank Interference
  - Canadian banks have been known to block money transfers to online cryptocurrency platforms, thus restricting your money how you want to use it.  With an ATM there is no hold or restriction on funds as they are cash only transactions.
  # Speak with Someone Instantly and Directly
  - You can call and speak with someone directly on how to purchase cryptocurrency for the first time with ATMs.
  `,

  'How do I buy cryptocurrency at an ATM?': `
  
  It is very easy to buy cryptocurrency at a Bitcoin Mart ATM and takes under 5 minutes for new clients.  
  1.	Select the cryptocurrency you would like to purchase (BTC, ETH or LTC)
  2.	Input your Canadian phone number into the machine to receive a one-time password
  3.	Scan your wallet address
  4.	Input the amount of cash you would like to purchase
  5.	Receive your cryptocurrency, that easy!
  \n&nbsp;
  If there are any questions reach out and we can assist you through the process
  
  `,
  'What cryptocurrencies can I buy at an ATM?':
    'Currently you can purchase **Bitcoin (BTC), Ethereum (ETH) and Litecoin (LTC)** at all Bitcoin Mart ATM locations.',
  'How do I hold/ store cryptocurrency?': `
  
  - Cryptocurrency cannot be physically held.  Cryptocurrency is stored on the blockchain and access is granted to it through a cryptocurrency wallet.  

  - You must have a wallet in order to purchase and hold any cryptocurrencies.
  - The ATM will confirm the wallet you are sending cryptocurrency to is the correct type of wallet.  A Bitcoin wallet is different from an Ethereum and/ or Litecoin wallet. 
  `,
  'How do I get a cryptocurrency wallet?': `
  It is highly recommended to back up your wallet by writing down the private key or recovery phrase to your wallet on a piece of paper and storing it in a hidden location.  Failing to do so could result in a permanent loss in funds.
  # User-Friendly Mobile Wallets
  -	Coinomi - Available on both [Android](https://play.google.com/store/apps/details?id=com.coinomi.wallet) and [iOS](https://apps.apple.com/app/coinomi-wallet/id1333588809) 
  - Trust Wallet (shows funds in USD by default) -	Available on both [Android](https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&referrer=utm_source%3Dwebsite) and [iOS](https://apps.apple.com/app/apple-store/id1288339409?mt=8) 

  # Hardware Wallets
  These are the most expensive options, but is the most secure way of storing your cryptocurrency  
  - [Trezor](https://trezor.io/)  
  -	[Ledger](https://www.ledger.com/)  
  \n&nbsp;
  _Bitcoin Mart Ltd are not associated or affiliated with any of the wallet providers listed above.  It should be noted that any and all wallets you decide to use should be researched before using.  Bitcoin Mart Ltd. Is not responsible for any lost or stolen funds as result of using the wallets.  It is understood that the user is responsible for their own actions and research on how to use them correctly._


`,
  'How much does it cost to buy cryptocurrency?': `
  The price of cryptocurrencies changes minute to minute.  Our prices for bitcoin, Ether and Litecoin are based on the market rate in addition to an exchange fee, similar to international currency conversions.
  `,
  'Do I have to buy a full bitcoin?': `**No**, bitcoin can be purchased in any increment; start stacking sats today with as little as $20 at your local Bitcoin Mart ATM.`,
  'How much cryptocurrency can I buy from an ATM?': `
  We allow for purchases up to $980 CAD per transactions with a total aggregate transaction limit of $9980 CAD per day. `,

  'Can I buy cryptocurrency without a mobile phone?': `**No**, you cannot buy cryptocurrency from a Bitcoin Mart ATM without a phone.  A text message verification is required to execute any and all transactions.`,

  'Is cryptocurrency illegal in Canada?': `
  Although cryptocurrencies are not legal tender in Canada, it is not illegal to own, send or mine cryptocurrency in Canada.  Cryptocurrency transactions and institutions are regulated by the Financial Transactions and Reports Analysis Center of Canada (FINTRAC).  Bitcoin Mart Ltd is fully registered and follows all FINTRACT guidelines regarding cryptocurrency transactions.`,
  'Is cryptocurrency taxable in Canada?': `

  The Canadian Revenue Agency (CRA) does not consider holding or storing cryptocurrency a taxable event.  You may be involved in a taxable scenario in some of the following examples:
  - Selling or converting cryptocurrency into Canadian Dollar.
  -	Selling or converting one cryptocurrency into another cryptocurrency
  -	Buying goods or services with cryptocurrencies\n
  &nbsp;
  [Click here](https://www.canada.ca/en/revenue-agency/programs/about-canada-revenue-agency-cra/compliance/digital-currency/cryptocurrency-guide.html) for full information regarding taxes in Canada with cryptocurrencies.  \n
  &nbsp;
  *Bitcoin Mart Ltd is not able to offer any tax advice regarding the cryptocurrency it offers.  It is advised to speak with a certified tax professional regarding questions about your potentially taxable scenarios.*


  `,
  'I have an issue or question about my transaction': `
  Please [Contact us](/contact-us)`,
};
