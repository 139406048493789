import { Center, Stack, Heading, Image } from '@chakra-ui/react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { BTCLogo, ETHLogo, LTCLogo } from '../../assets/imgs'
import PageLayout from '../layouts/page-layout'

//! ONLY TEMPORARY
const LEARN_ITEMS = {
    "btc": {
        href: '/learn/bitcoin',
        title: 'Learn about Bitcoin',
        icon: BTCLogo,
    },
    "eth": {
        href: '/learn/ethereum',
        title: 'Learn about Ethereum',
        icon: ETHLogo
    },
    "ltc": {
        href: '/learn/litecoin',
        title: 'Learn about Litecoin',
        icon: LTCLogo,
    },
    "crypto": {
        href: '/faq',
        title: 'Why Do People Buy Crypto?',
        icon: null,
    }
}

const LearnCardFactory = (type, data) => {
    return <Center as="a" href={data.href} justifyContent={'start'} rounded="lg" bg="dark.10" p={8} className="smooth" border="solid 1px" borderColor="dark.100" _hover={{ bg: 'dark.50' }} borderLeft="4px" borderLeftColor="black">
        <Stack direction="row" spacing={4} alignItems="center">
            {data.icon && <Image src={data.icon} boxSize="40px" />}
            <Heading textAlign="left" fontWeight="600">{data.title}
            </Heading>
        </Stack>
    </Center>
}

const LearnPage = () => {
    return (
        <div>
            <Helmet>
                <title>Learn</title>
                <meta name="description" content="Get a basic introduction into the world of cryptocurrencies." />
                <link rel='canonical' href='/learn' />
            </Helmet>
            <PageLayout maxW="container.md" padChildren title="Learn" subtitle="Get a basic introduction to the world of cryptocurrencies">
                <Stack>
                    {Object.entries(LEARN_ITEMS).map(([k, v]) => {
                        return LearnCardFactory(k, v)
                    })}
                </Stack>

            </PageLayout>
        </div>
    )
}

export default LearnPage