import React from 'react'
import Navbar from '../Navbar/navbar';
import Footer from '../Footer/Footer'
import {
    Box,
    Container,
} from '@chakra-ui/react';
import CryptoWithCashSection from './LandingSections/CryptoWithCashSection';
import HowToBuyCryptoSection from './LandingSections/HowToBuyCryptoSection';
import LandingSection from './LandingSections/LandingSection';
import FindALocationSection from './LandingSections/FindALocationSection';
import { Helmet } from 'react-helmet-async';

export default function Landing() {
    return (
        <div>
            <Helmet>
                <title>BitcoinMart ATM CA</title>
                <meta name="description" content="The quickest and most secure way to get into crypto." />
                <link rel='canonical' href='/' />
            </Helmet>
            <Box textAlign="center" fontSize="xl">

                <LandingSection />
                <Box className="polka">
                    <Container py={12}>
                        <CryptoWithCashSection />
                    </Container>
                </Box>
                <Box>
                    <Container py={12}>
                        <HowToBuyCryptoSection />
                    </Container>
                </Box>
                <Box bg="app.primaryDark">
                    <Container py={12}>
                        <FindALocationSection />
                    </Container>
                </Box>
            </Box>
        </div >
    );
}