import { GoogleMap } from "@react-google-maps/api";
import React, { useMemo, useState } from 'react'
import CustomMarker from "./CustomMarker";
import { ATMLocations as LocationJSON } from '../../__WEBSITE_DATA/atm-locations';

export default function Map(props) {
    const [mapInstance, setMapInstance] = useState();
    const isLoaded  = props.isLoaded;
    const center = useMemo(() => (props.center ? props.center : { lat: 44, lng: -80 }), [props.center]);
    const LocationData = LocationJSON.Locations;
    
    const containerStyle = {
        width: '100%',
        height: '100%'
    };
    
    const onLoadMap = map => {
        setTimeout(() => setMapInstance(map));
    }

    if (!isLoaded) 
        return null;
    
    return (
        <GoogleMap onLoad={onLoadMap} zoom={6} center={center} mapContainerStyle={containerStyle}>
            {mapInstance && LocationData.map(item =>
                <CustomMarker title={item.Title} address={item.Address} mapInstance={mapInstance} location={{ lat: item.latitude, lng: item.longitude }}/>
            )}
        </GoogleMap>
    );
}