import React from 'react'
import PageLayout from '../layouts/page-layout'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text,
} from '@chakra-ui/react'
import faqData from '../../json/faq-data'

import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import { Helmet } from 'react-helmet-async'

const FAQPage = () => {
    return (
        <PageLayout title="Frequently Asked Questions">
            <Helmet>
                <title>Frequently Asked Questions</title>
                <meta name="description" content="Find frequently asked questions about our services." />
                <link rel='canonical' href='/faq' />
            </Helmet>
            <Accordion borderX="1px" borderColor="dark.100" allowMultiple>
                {Object.entries(faqData).map(([k, v]) => {
                    return <AccordionItem>
                        <Box role="group" >
                            <AccordionButton bg="dark.10" p={4}>
                                <Box flex='1' textAlign='left' color="dark.800" _groupHover={{ marginLeft: 2, color: 'black' }} className="smooth">
                                    <Text fontWeight="600" fontSize="2xl">{k}</Text>
                                </Box>
                                <AccordionIcon boxSize={10} />
                            </AccordionButton>
                        </Box>
                        <AccordionPanel p={4}>
                            <ReactMarkdown linkTarget="_blank" className='md' parserOptions={{ commonmark: true }} remarkPlugins={[remarkBreaks]}>{v}</ReactMarkdown>
                        </AccordionPanel>
                    </AccordionItem>
                })}
            </Accordion>
        </PageLayout>
    )
}

export default FAQPage