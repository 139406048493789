import { Container, Heading, Stack, Text, Box } from '@chakra-ui/react';
import React from 'react';

const PageLayout = ({ title, subtitle, children, padChildren, maxW }) => {
  return (
    <Container maxW={maxW || 'container.xl'}>
      <Stack my={{ base: 12, lg: 32 }} spacing={8}>
        <Stack p={4} textAlign="center">
          <Heading fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}>{title}</Heading>
          <Text>{subtitle}</Text>
        </Stack>
        <Box p={padChildren ? 4 : 0} pt="0">
          {children}
        </Box>
      </Stack>
    </Container>
  );
};

export default PageLayout;
